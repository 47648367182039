import styled from '@emotion/styled';
import themeUtils from '../../lib/themeUtils';

const getSize = props =>
  props.size
    ? themeUtils.spacings[props.size]
    : themeUtils.spacings.md;

const Spacer = styled.div`
  margin-bottom: ${p => (p.vertical ? getSize(p) : 0)};
  margin-right: ${p => (p.horizontal ? getSize(p) : 0)};
`;

export default Spacer;
