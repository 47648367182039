import styled from '@emotion/styled';
import themeUtils from '../../lib/themeUtils';
import { Link } from '.';

const EmailLink = styled(Link)`
  font-size: ${themeUtils.fontSizes.sm};

  &:hover {
    text-decoration: underline;
  }
`;

export default EmailLink;
