import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import themeUtils from '../../lib/themeUtils';
import { getTransition } from '../../lib/style/transition';

const getMinWidth = p => (p.size === 'lg' ? '120px' : 'auto');

export const getBasicStyle = p => css`
  ${p.variant === 'block'
    ? css`
        display: block;
        width: 100%;
      `
    : css`
        display: inline-block;
        width: auto;
      `}
  font-family: 'industry';
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  font-size: ${themeUtils.fontSizes.sm};
  line-height: ${themeUtils.fontSizes.sm};
  padding: 12.5px 16px;
  min-width: ${getMinWidth(p)};
  color: ${themeUtils.getTheme(p).colors.bg1};
  background-color: ${themeUtils.getTheme(p).colors.title};
  ${getTransition()}
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${themeUtils.getTheme(p).colors.title};
    background-color: ${themeUtils.getTheme(p).colors.bg1};
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    box-shadow: none;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
`;

const StyledInputButton = styled.button`
  ${getBasicStyle}
`;
const StyledLinkButton = styled.a`
  ${getBasicStyle}
`;

const StyledButtonGroup = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  > ${StyledInputButton}, > ${StyledLinkButton} {
    position: relative;
    flex: 0 1 auto;

    &:hover,
    &:focus,
    &:active {
      z-index: 1;
    }

    &:first-child {
      margin-left: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

const Button = props =>
  props.href ? (
    <StyledLinkButton {...props} />
  ) : (
    <StyledInputButton {...props} />
  );

Button.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  variant: PropTypes.oneOf(['block']),
  disabled: PropTypes.bool,
  href: PropTypes.string,
  className: PropTypes.string,
};
Button.defaultProps = {
  size: 'sm',
};

export default Button;
export { StyledButtonGroup as ButtonGroup };
