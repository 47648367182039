import styled from '@emotion/styled';

const Code = styled.code`
  font-family: industry;
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
`;

export default Code;
