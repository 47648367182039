import { Link } from 'gatsby';
import React from 'react';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import { Container, SideSlideMenu as Menu } from '../blocks';
import themeUtils from '../../lib/themeUtils';
import Logo from './Logo';

const { getTheme } = themeUtils;

const menuBtn = {
  width: 18,
  height: 18,
};
const headerHeights = [70];
const responsiveBodyPadding = themeUtils.mq({
  paddingTop: themeUtils.sizeToPx(headerHeights),
});
const responsiveMenuTop = themeUtils.mq({
  top: themeUtils.sizeToPx(headerHeights.map(x => x / 2 - menuBtn.height / 2)),
});

const headerItemCss = css`
  display: flex;
  height: 100%;
  align-items: center;
  text-decoration: none;
`;

const menuItems = [
  {
    to: '/',
    text: 'HOME',
  },
  {
    to: '/team/',
    text: 'TEAM',
  },
  {
    to: '/proposal/',
    text: 'PROPOSAL',
  },
];
const getMenuItemsJsx = ({ css } = {}) => {
  return menuItems.map(menuItem => (
    <Link
      key={menuItem.text.toLowerCase()}
      to={menuItem.to}
      activeClassName="is-active"
      css={css}
    >
      {menuItem.text}
    </Link>
  ));
};

const DesktopMenuContainer = styled.div`
  ${themeUtils.mq({
    display: ['none', null, 'flex'],
  })}

  a {
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    margin-right: 50px;
    font-size: ${themeUtils.fontSizes.sm};
    font-family: 'industry';
    font-weight: 700;
    color: ${p => getTheme(p).colors.menu.normal};

    &:hover {
      color: ${p => getTheme(p).colors.menu.hover};
    }
    &.is-active {
      color: ${p => getTheme(p).colors.menu.active};
      border-bottom: 3px solid ${p => getTheme(p).colors.menu.active};
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
const DesktopMenu = props => {
  return (
    <DesktopMenuContainer {...props}>
      {getMenuItemsJsx({
        css: css`
          ${headerItemCss}
        `,
      })}
    </DesktopMenuContainer>
  );
};

const Header = () => (
  <div
    className="Header"
    css={css`
      background: #ffffff;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      ${themeUtils.mq({
        height: themeUtils.sizeToPx(headerHeights),
      })}

      .SideSlideMenu {
        ${themeUtils.mq({
          display: ['block', null, 'none'],
        })}
      }

      .SideSlideMenu .menu-toggle {
        width: ${menuBtn.width}px;
        height: ${menuBtn.height}px;
        ${responsiveMenuTop}

        .menu-icon {
          height: 16px;
        }
      }
    `}
  >
    <Global
      styles={css`
        body {
          ${responsiveBodyPadding}
        }
      `}
    />
    <Container
      className="Header--container"
      css={css`
        height: 100%;
        display: flex;
        justify-content: space-between;
      `}
    >
      <Link
        to="/"
        className="logo"
        css={css`
          ${headerItemCss}
        `}
      >
        <Logo
          horizontal
          css={themeUtils.mq({
            height: '46px',
            display: 'block',
          })}
        />
      </Link>
      <DesktopMenu className="menu-desktop" />
    </Container>
    <Menu right menuPaddingTop={themeUtils.sizeToPx(headerHeights)}>
      {getMenuItemsJsx()}
    </Menu>
  </div>
);

export default Header;
