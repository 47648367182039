import { css } from '@emotion/core';

function getTransition({
  property = 'all',
  duration = '0.2s',
  timing = 'ease',
} = {}) {
  return css`
    transition: ${property} ${duration} ${timing};
  `;
}

export { getTransition };
