import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import themeUtils from '../../lib/themeUtils';

const commonCss = css`
  margin-top: ${themeUtils.spacings.sm};
  margin-bottom: ${themeUtils.spacings.md};
`;
const ListItem = styled.li;
const StyledOrderedList = styled.ol`
  ${commonCss}
`;
const StyledUnOrderedList = styled.ul`
  ${commonCss}
`;

const List = ({ ordered, ...props }) => {
  const Component = ordered ? StyledOrderedList : StyledUnOrderedList;

  return <Component {...props} />;
};
List.propTypes = {
  ordered: PropTypes.bool,
};

export default List;
export { ListItem };
