import facepaint from 'facepaint';
import { Object } from 'es6-shim';

const spacer = 35;
const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};
const mediaQueries = Object.values(breakpoints).map(
  bp => `@media (min-width: ${bp}px)`
);
const fontSizes = {
  xxs: '0.625rem',
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  mlg: '1.125rem',
  lg: '1.375rem',
  xl: '1.5rem',
  xxl: '2rem',
};
const spacings = {
  xs: `${spacer / 4}px`,
  sm: `${spacer / 2}px`,
  md: `${spacer}px`,
  lg: `${spacer * 1.5}px`,
  xl: `${spacer * 2}px`,
};

const themes = {
  light: {
    colors: {
      bg1: '#ffffff',
      bg2: '#f2f2f2',
      text: '#4d4d4d',
      primary: '#0c5ecd',
      title: '#000000',
      menu: {
        normal: '#808080',
        active: '#000000',
        hover: '#000000',
      },
    },
    padding: {
      container: ['15px', null, null, '40px', '70px'],
    },
  },
};

export default {
  breakpoints,
  fontSizes,
  spacings,
  mq: facepaint(mediaQueries),
  getTheme(props = {}) {
    const {
      theme: { mode },
    } = props;
    return themes[mode];
  },
  /**
   * Convert a number or an array of numbers to strings with 'px'
   */
  sizeToPx(subject) {
    if (!subject.map) {
      if (typeof subject !== 'number' && typeof subject !== 'string') {
        return subject;
      }
      return `${subject}px`;
    }
    return subject.map(size => `${size}px`);
  },
};
