import styled from '@emotion/styled';

const Paragraph = styled.p`
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 2rem;
  line-height: 24px;
`;

export default Paragraph;
