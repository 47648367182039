import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledSvg = styled.svg`
  display: inline-block;
  font-size: inherit;
  width: 1em;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
`;

const FaSvg = ({ icon, ...props }) => (
  <StyledSvg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 0 ${icon.icon[0]} ${icon.icon[1]}`}
    {...props}
  >
    <path fill="currentColor" d={icon.icon[4]} />
  </StyledSvg>
);
FaSvg.propTypes = {
  icon: PropTypes.object,
};

export default FaSvg;
