import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import themeUtils from '../../lib/themeUtils';

const Separator = styled.div`
  width: 17px;
  border-bottom: #000000 solid 6px;
  margin-left: 0;
  margin-right: 0;
  margin-top: ${p => p.top ? themeUtils.spacings.md : 0};
  margin-bottom: ${p => p.bottom ? themeUtils.spacings.md : 0};
`;
Separator.propTypes = {
  top: PropTypes.bool,
  bottom: PropTypes.bool,
};
Separator.defaultProps = {
  top: true,
  bottom: true,
};

export default Separator;
